
@import "_variables.less";

//=============================
//== Helpers
//=============================

.text-size-large {
  font-size: @font-size-large;
}
.text-size-base {
  font-size: @font-size-base;
}
.text-size-small {
  font-size: @font-size-small;
}

.mb-40 { margin-bottom: 40px; }
.no-margin { margin: 0; }

@media only screen and (min-width : 768px) {

    .row.is-flex {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .row.is-flex > [class*='col-'] {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .row.is-flex .valign-center {
        justify-content: center;
        flex-direction: column;
    }

}