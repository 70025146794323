// Import globals
@import "../_global/global.less";

//== Typography
@font-family-sans-serif:    Lato, Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-base:          @font-family-sans-serif;
@font-size-base:            14px;
@font-size-large:           ceil((@font-size-base * 1.25)); // ~18px
@font-size-small:           ceil((@font-size-base * 0.85)); // ~12px
@line-height-base:          1.428571429; // 20/14

//== Navbar
@navbar-height:             80px;
@navbar-tabs-height:        49px;
@contextbar-height:         68px;

//== Sidebar
@sidebar-base-width:        260px;

//== Modals
@modal-full:                100%;
@modal-sm:                  400px;
@modal-xs:                  300px;

//== Icons
@icon-font-size:            24px;
@icon-font-size-small:      18px;