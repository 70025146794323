
@import "_variables.less";

//=========================
//== Nav
//=========================
//== Navbar
//== Sidebar
//== Navbar Tabs
//== Navbar Connected
//== Context bar
//== Toolbar
//=========================


//=========================
//== Navbar
//=========================

.navbar {
    background-color: #FFF;
    box-shadow: none;
    border-bottom: 1px solid transparent;
}
@media (min-width: 769px) {
    .navbar {
        padding-left: 30px;
        padding-right: 30px;
    }
}
.nav > li > a {
    padding-bottom: 12px 18px;
}
.navbar-brand {
    min-height: @navbar-height;
    padding: 22px 12px 22px 0px;
    margin-right: 10px;
}
.navbar-brand > img {
    margin-top: 0;
    height: auto;
}
.navbar-header {
    width: 100%;
}
.navbar-header .navbar-nav > li > a,
.navbar-header .navbar-right > li > a {
    padding-left: 15px;
    padding-right: 15px;
}
.navbar-header .navbar-right > li > a {
    margin-top: 8px;
}
/*
.navbar-xs .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 21px;
}*/
#navbar-second {
    z-index: 1;
}
.navbar-bordered-bottom {
    border-bottom: 5px solid transparent;
}
.navbar-nav > li > a {
    font-size: 16px;
    line-height: 26px;
}
.navbar-nav > li > a:after {
    display: none;
}
@media (min-width: 769px) {
    .navbar-nav > li {
        padding-bottom: 5px;
    }
    .navbar-nav > li:after {
        content: '';
        position: absolute;
        height: 5px;
        top: auto;
        bottom: 0px;
        left: 0;
        background-color: transparent;
        width: 100%;
        z-index: 5;
    }
}
.navbar-nav > li > a .ionicon, .navbar-nav > li > a .mdi {
    float: left;
    font-size: @icon-font-size;
    margin-right: 10px;
}
.navbar-header .navbar-nav {
    float: left;
    margin: 0;
}
.navbar-header .navbar-nav > li {
    margin: 10px 5px;
}
.navbar-right {
    display: none;
}
@media (min-width: 769px) {
    .navbar-right {
        display: block;
    }
}
.navbar-right > li {
    float: left;
    margin: 2px 10px;
}
.navbar-right .navbar-nav {
    margin-left: 0;
}
.navbar-right .navbar-nav .dropdown > a {
    padding: 13px;
    line-height: 54px;
}
/*
.navbar-right .navbar-nav .dropdown .ionicon {
    font-size: 14px;
    margin-left: 15px;
}
*/
.navbar-right .navbar-nav .dropdown img {
    float: left;
    margin-right: 15px;
}
.navbar-right .navbar-nav > li.dropdown {
    margin: 0;
}
.navbar-header .navbar-nav i,
.navbar-right .ionicon,
.navbar-right .mdi {
    font-size: @icon-font-size-small;
}
@media (min-width: 769px) {
    .navbar-header {
        margin-left: 0;
    }
    .navbar-nav {
        margin-left: 0px;
    }
    .navbar-collapse {
        margin-left: 0;
    }
}
@media (max-width: 768px) {
    .navbar-collapse .navbar-nav {
        padding-top: 0px;
        padding-bottom: 0px;
    }
}
.dropdown-menu-right {
    font-size: 16px;
}
.dropdown-apps {
    max-width: none;
    width: 350px;
    text-align: center;
}
.dropdown-apps li {
    display: inline-block;
    margin: 0px 5px;
}
.dropdown-apps > li > a {
    padding: 15px;
    overflow: auto;
    text-overflow: initial;
}
.dropdown-apps > li > a > img {
    margin: 0 ! important;
    float: none !important;
    max-height: none !important;
}
.dropdown-apps li:last-child { margin-bottom: 0px; }

.sidebar-nav li + li {
    margin-top: 0;
    border-top: 1px solid transparent;
}
.sidebar-nav > li + li {
    margin-top: 1px;
}
.sidebar-nav > li {
    position: relative;
}

.sidebar-nav > li > a {
    display: block;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    padding: 16px 20px 16px 25px;
    transition: background 0.15s linear, color 0.15s linear;
}
.sidebar-nav {
    margin-top: 35px;
}
.sidebar-nav.affix {
    top: 0;
    width: @sidebar-base-width;
}


//=========================
//== Sidebar
//=========================

#sidebar .sidebar-user-material-content {
    display: block;
}
.sidebar-user-material .navigation {
    padding: 0;
}
.sidebar-user-material .navigation > li > a {
    padding: 15px 30px
}
#sidebar {
    background: #FFF;
}
.sidebar-wrapper {
  position: relative;
  height: 100%;
  overflow: auto;
}
.close-sidebar {
    display: none;
}
.navigation li + li {
    margin-top: 15px;
}
.navigation > li + li {
    margin-top: 0;
    border-top: 1px solid transparent;
}
.navigation > li > ul li:last-child {
    margin-bottom: 15px;
}
.navigation li + li.divider {
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.navigation > li ul li .form-group {
    padding-left: 30px;
    margin: 10px 0;
}
#toggle-sidebar {
    color: #333;
}
#toggle-sidebar > i {
    font-size: 30px;
}
.sidebar-default {
    box-shadow: none;
    border-right: 1px solid transparent;
}
.sidebar-default .navigation > li > a {
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
    padding: 16px 30px 16px 30px;
}
.sidebar-default .navigation li a > i {
    width: 25px;
    margin-right: 10px;
    margin-top: 0px;
}
@media (min-width: 769px) {
    .sidebar-xs .sidebar-main .navigation > li > a > i {
        margin: 0 auto;
    }
}
.sidebar-default .ionicon, .sidebar-default .mdi {
    font-size: @icon-font-size;
}
.sidebar-default .nav > li > a:hover, 
.sidebar-default .nav > li > a:focus {
    background-color: transparent;
}
.sidebar-main {
    z-index: 50;
    padding-top: 0px;
}
@media (min-width: 769px) {
    .sidebar-nav-control {
        display: none !important;
    }
}
@media (min-width: 769px) {
    .sidebar-xs .sidebar-main {
        width: @sidebar-base-width;
    }
}
.navbar-collapse.collapse {
    display: block;
}
.navbar-tabs .navbar-nav > li {
    float: left;
}

//=========================
//== Navbar Tabs
//=========================

.navbar-tabs {
    width: 100%;
    clear: both;
    height: @navbar-tabs-height;
    border-top: 1px solid transparent;
    padding: 0 20px;
}
@media (min-width: 768px) {
    .navbar-tabs-inner {
        position: inherit;
        display: block;
        width: auto;
    }
    .navbar-tabs {
        width: auto;
        padding: 0;
    }
    .navbar-tabs .navbar-nav {
        width: auto;
        position: absolute;
        top: 0px;
        float: left;
    }
}
.navbar-tabs .navbar-nav > li a {
    text-align: center;
    font-size: 20px;
    padding: 11px 18px 12px 18px;
}
@media (max-width: 1400px) {
    .navbar-tabs .navbar-nav > li .text {
        display: none;
    }
    .navbar-tabs .navbar-nav > li i {
        padding-right: 0;
    }
}


//=========================
//== Navbar Connected
//=========================

.navbar-connected {
    display: none;
    float: right;
    margin: 8px 20px 8px 0;
}
@media (min-width: 769px) {
    .navbar-connected {
        display: block;
        margin-right: 0px;
    }   
}
.navbar-connected > li {
    margin: 0px 5px;
}
.navbar-connected > li > a {
    padding: 0;
}


//=========================
//== Context bar
//=========================

.context-bar-wrapper {
    position: relative;
    height: @contextbar-height;
}
.context-bar {
    display: table;
    position: absolute;
    width: 100%;
    height: @contextbar-height;
    top: 0;
    left: 0;
    padding: 0px 10px;
}
@media (min-width: 480px) {
    .context-bar {
        padding: 0px 25px;
    }
}
.context-bar > * {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
}
.context-bar .context-bar-left {
    width: 33%;
}
.context-bar .context-bar-right {
    width: 33%;
}
.context-bar .context-bar-center {
    text-align: center;
}
.context-bar .context-bar-center h2 {
    font-size: 18px;
    margin: 20px 0;
}
/*
.context-bar .btn-group {
    margin: 15px 0;
}
*/
.context-bar {
    border-bottom: 1px solid transparent;
}
.context-bar .ionicon, .context-bar .mdi {
    font-size: 24px;
    float: left;
    line-height: 20px;
}
.context-bar ul {
    margin: 0;
    padding: 0;
}


//=========================
//== Toolbar
//=========================

.toolbar { 
    border-top: 1px solid transparent;
}
.toolbar li {
    border-right: 1px solid transparent;
}