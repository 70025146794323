
@import "_variables.less";

//=============================
//== Content
//=============================

.content-wrapper {
    position: relative;
}

.content {
    padding: 0 20px 100px 20px ! important;
}

.category-content {
    padding: 30px;
}

.content > .row > div[class*='col-'] {
    padding: 0 20px;
}
@media (min-width: 480px) {
    .content > .row > div[class*='col-'] {
        padding: 0 40px;
    }
}

.page-header-default {
    background-color: transparent;
    box-shadow: none;
    margin: 0px;
}

.panel {
    box-shadow: none;
    border-radius: 0;
}

.panel-heading,
.panel-body {
    padding: 10px 0px;
}

.panel-heading h1 {
    margin-top: 15px;
}

@media (min-width: 480px) {
    .panel-heading,
    .panel-body {
        padding: 30px 20px;
    }
    .panel-heading {
        padding-bottom: 0;
    }
}

.panel-body > .media-list .contact-details {
    margin-top: 0;
    padding: 0 30px 20px 30px;
    border: none;
}

.panel-body h1 {
    margin-bottom: 0;
    margin-top: 10px;
}

.panel-apps [class*='col-'] {
    margin-bottom: 20px;
}