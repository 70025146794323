
@import "_variables.less";

//=========================
//== Components
//=========================
//== Lists
//== Search
//== Cards
//== Slides
//== FAB Menu
//== Modals
//== Login
//== Calendar
//=========================


//=========================
//== Lists
//=========================

.media-list-linked .media-link {
    position: relative;
    display: inline-block;
    transition: all 0.2s;
    cursor: pointer;
    padding: 10px 15px;
}
.icons-list-extended > li {
    margin-left: 10px !important;
}
.icons-list-extended > li > a {
    display: inline-block;
    padding: 10px;
    min-width: 20px;
    text-align: center;
}
.icons-list {
    opacity: 0;
    position: absolute;
    top: 25px;
    right: 20px;
    transition: all 0.2s;
}
.icons-list .ionicon {
    font-size: 22px;
    line-height: 22px;
}
.media-list-linked .media-link.hover,
.media-list-linked .media-link.active {
    background-color: #fafafa;
}
.media-list-linked .media-link.hover .icons-list {
    opacity: 1;
}
.media-body h5 {
    margin: 0 0 5px 0;
}


//=============================
//== Search
//=============================

.input-search {
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    border-radius: 0px;
    padding: 8px 16px 8px 60px;
    height: 54px;
    margin-top: 13px;
    transition: all 0.2s ease-out;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;    
    box-sizing: border-box;
}
.input-search::-webkit-input-placeholder {
    font-size: 16px;
    font-weight: 300;
    color:#666;
}
.input-search:-moz-placeholder { /* Firefox 18- */
    font-size: 16px;
    font-weight: 300;
    color:#666;
}
.input-search::-moz-placeholder { /* Firefox 19+ */
    font-size: 16px;
    font-weight: 300;
    color:#666;
}
.input-search:-ms-input-placeholder {
    font-size: 16px;
    font-weight: 300;
    color:#666;
}
.input-search:active,
.input-search:focus,
.input-search:hover {
    background-color: #FFF;
    border-color: transparent;
}
.search-group {
    position: relative;
    float: left;
    padding: 0;
}
.search-group i {
    position: absolute;
    font-size: 22px;
    top: 25px;
    left: 35px;
}
@media (min-width: 480px) and (max-width: 768px) {
    .search-group {
        width: 55%;
    }
}
@media (max-width: 479px) {
    .input-search {
        margin-top: 0;
        margin-bottom: 00px;
    }
    .search-group i {
        top: 13px;
    }
}
.global-search-results {
    position: absolute;
    width: 100%;
    left: 0;
    background-color: #FFF;
    box-shadow: 0px 1px 10px rgba(0,0,0,0.1);
    z-index: 999;
}
.global-search-results h5 {
    position: relative;
    padding-bottom: 10px;
    margin-top: 20px;
    margin-bottom: 40px;
    /*border-bottom: 1px solid transparent;*/
}

.global-search-results h5:after {
    position: absolute;
    content: '';
    width: 30px;
    height: 1px;
    left: 0px;
    bottom: -10px;
}
.global-search-results h5:first-child {
    margin-top: 0px;
}
.global-search-results h5 span {
    display: inline-block;
    font-size: 14px;
    margin-left: 10px;
}
.search-results-inner {
    width: 100%;
    max-height: 90vh;
}
@media (min-height: 768px) {
    .search-results-inner {
        max-height: 500px;
    } 
}
.search-results-panel:first-child {
    padding-top: 20px;
}
.search-results-panel {
    padding: 0 30px 20px 30px;
}
.search-results-panel .item {
    margin-bottom: 20px;
}
.search-results-panel .item > a {
    text-align: center;
}
.search-results-panel .item > a > .item-image > .img-circle {
    max-width: 100%;
    height: auto;
    margin-bottom: 5px;
}
.search-results-panel .item > a > .item-text {
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.search-results-panel .item > a > .item-subtext {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.mask {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 900;
    background: rgba(0,0,0,0.2);
}


//=========================
//== Cards
//=========================

.card-group-wrapper {
    border-radius: 3px;
    margin-bottom: 20px;
    border: 1px solid transparent;
}
.card-group-wrapper::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.card-group {
    position: relative;
    display: table;
    width: 100%;
}
.card-group .left,
.card-group .middle,
.card-group .right {
    display: table-cell;
    vertical-align: middle;	
    height: 85px;
}
.card-group h3 {
    margin: 0px;
    font-weight: 700;
}
.card-group .btn .caret:after {
    font-size: 24px;
}
.card-group button {
    height: 100%;
    float: right;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.card-group .left img {
    max-width: 50px;
    margin: 0 auto;
}
.card-group .left,
.card-group .middle {
    padding: 15px;
}
.card-group .left {
    padding-right: 0;
}
.card-group .title {
    font-size: 1.5em;
}
.card-group .value {
    font-size: 1.5em;
    font-weight: 700;
}


//=========================
//== Slides
//=========================


.label-vertical .control-label {
    text-align: center !important;
    position: absolute;
    width: 150px;
    bottom: 50px;
    padding: 5px 10px;
    background-color: #f9f9f9;
    left: -60px;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.05em;
    color: #333;
    text-transform: uppercase;
    transform: rotate(-90deg);
}

.label-vertical {
    min-height: 124px;
    position: relative;
    padding-left: 50px;
    margin-bottom: 0px;
}

.slides .owl-item {
    position: relative;
}

.slides .owl-item a {
    transition: all 0.3s;
}

.slides .owl-item .ionicon {
    font-size: 46px;
}

.slides .owl-item .ionicon.mini {
    font-size: 18px;
}

.slides .owl-item .item-image {
    max-width: 80px;
    margin: 0 auto;
    height: auto;
    border: 3px solid transparent;
    border-radius: 100%;
}

.slides .owl-item .item-text {
    display: inline-block;
    padding: 10px;
    font-size: 16px;
    overflow-wrap: break-word;
}

.slides .owl-nav > div {
    position: absolute;
}

.slides .owl-nav  .owl-prev {
    top: 40%;
    padding: 15px;
    left: -15px;
    transform: translateY(-50%);
}

.slides .owl-nav  .owl-next {
    top: 40%;
    padding: 15px;
    right: -15px;
    transform: translateY(-50%);
}

.slides .owl-item a {
    color: #000;
}

.slides .owl-item .item-text {
    padding: 10px;
}

.slides-actions a {
    display: inline-block;
    text-align: center;
    font-size: 28px;
    line-height: 50px;
    margin-top: -25px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
}


//=========================
//== FAB Menu
//=========================

.fab-menu-btn > i {
    font-size: 24px;
    line-height: 24px;
}

.fab-menu-btn > i,
.fab-menu-btn:hover > i {
    color: #FFF;
}

.fab-menu-btn.btn-float > i {
    margin-top: -11px;
}


//=========================
//== Modals
//=========================

@media (min-width: 769px) {
    .modal {
        padding-right: 0px !important;
    }
    .modal-full {
        width: @modal-full;
        margin: 0 auto;
    }
    .modal-dialog.modal-full {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    .modal-full .modal-content {
        height: auto;
        min-height: 100%;
        border-radius: 0;
    }
}


//=========================
//== Login
//=========================

.login-container {
    overflow: hidden;
    padding-top: 0px;
}
@media (min-width: 480px) {
    .login-container {
        padding-top: 80px;
    }
}
.login-container .panel-body, 
.login-container .panel-heading {
    padding: 40px;
}

.login-container .page-container .login-form {
    width: 250px;
}
.login-container .page-container .login-form .btn {
    margin: 10px 0;
}
@media (min-width: 480px) {
    .login-container .page-container .login-form {
        width: 380px;
    }
}
@media (min-width: 768px) {
    .login-container .page-container .login-form {
        width: 420px;
    }
    .login-container .page-container .login-form .btn {
        margin: 0;
    }
}

.login-container .panel-body {
    box-shadow: 0px 1px 10px #eee;
}

.login-container .form-control {
    font-size: 16px;
}

.login-header {
    margin: 50px 0 50px 0;
}

.login-header .navbar-brand > img {
    display: inline-block;
}

.login-header .navbar-brand {
    float: none;
    margin: 0 auto;
}

.login-container .page-container {
    padding-top: 0px;
}

.login-container .login-apps {
    text-align: center;
    margin: 20px auto 20px auto;
}

.login-container  .content {
    padding-top: 10px;
}

.login-container .login-apps li {
    margin: 10px;
    padding: 0;
}
.login-container .login-apps li img {
    margin: 0 auto;
}
.panel-bottom-links a {
    color: #888;
}

.sidebar-xs .sidebar-user-material > .category-content {
    padding: 30px;
}
.row-actions .btn {
    margin: 10px;
}
@media (min-width: 480px) {
    .row-actions .btn {
        margin: 0px;
    }
}


//=========================
//== Calendar
//=========================

.fullcalendar-basic {
    position: relative;
    z-index: 0;
}