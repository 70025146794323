
@import "_variables.less";

//=============================
//== Colors
//=============================

.bg-primary,
.btn-primary  {
    background-color: @brand-primary;
    border-color: @brand-primary;
}

.btn-block-group {
    box-shadow: none;
}

.btn-primary:active:hover, 
.btn-primary.active:hover, 
.btn-primary:active:focus, 
.btn-primary.active:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.btn-primary:focus, 
.btn-primary.focus, 
.btn-primary:hover,
a.bg-primary:hover,
a.bg-primary:focus {
    background-color: darken(@brand-primary, 7%);
    border-color: darken(@brand-primary, 7%);
}

.btn-more {
    background-color: @gray-light; 
    color: @text-color;
}

.form-control:focus,
.select2-container--focus .select2-selection--single:not([class*=bg-]):not([class*=border-]), 
.select2-container--open .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
    border-bottom-color: @brand-primary;
    box-shadow: 0 1px 0 @brand-primary;
}

.select2-results__option[aria-selected=true] {
    background-color: @brand-primary;
}

.daterangepicker td.active, 
.daterangepicker td.active:hover, 
.daterangepicker td.active:focus {
    background-color: @brand-primary;
}

.fab-menu-btn {
    background-color: @brand-primary;
}

.dashboard-container .content {
    background-color: @body-bg;
}

.content {
    background-color: #FFF;
}

.category-content + .category-content {
    border-top: 1px solid @gray-light;
}

.row-actions .panel-body {
    background-color: @gray-light;
}