

.modal-body .panel-body {
    padding: 15px 10px;
}

.modal-body .panel-heading {
    margin-top: 3px;
    padding: 0;
}

.modal-body .panel-title > a {
    padding: 15px 10px;
    display: block;
}