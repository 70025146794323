
@import "_variables.less";

//=============================
//== Buttons
//=============================

.btn-more {
    border-radius: 100%;
    width: 50px;
    height: 50px;
}

.btn-more .ionicon {
    font-size: 26px;
}