
@import "_variables.less";

//=============================
//== Breadcrumb
//=============================

.breadcrumb-line {
    padding: 0 30px;
}

.page-header-default .breadcrumb-line:not([class*=bg-]) {
    background-color: transparent;
    box-shadow: none;
}

.breadcrumb > li {
    font-size: 22px;
    font-weight: 300;
}

.breadcrumb > li + li:before {
    font-size: 14px;
    padding: 0 10px 0 4px;
}

// .breadcrumb {
//   padding: 0;
// }

// .breadcrumb > li {
//   font-size: 22px;
//   font-weight: 300;
// }

// .breadcrumb > li + li:before {
//   font-size: 14px;
//   padding: 0 13px 0 4px;
// }

// .breadcrumb > li i {
//   font-size: 16px;
// }

// .breadcrumb-line {
//   box-shadow: none;
//   padding: 0 0 0 50px;
// }