
@import "_variables.less";

//=============================
//== Scaffolding
//=============================

body {
    position: relative;
    overflow-y: scroll;
    font-family: @font-family-base;
    font-size: @font-size-base;
    line-height: @line-height-base;
}