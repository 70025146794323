
@import "_variables.less";

//=============================
//== Forms
//=============================

.content-group {
    margin-bottom: 0px !important;
}

legend {
    font-size: @font-size-base * 1.2;
    padding-bottom: 15px;
    margin-bottom: 30px;
    margin-top: 30px;
    text-transform: none;
}